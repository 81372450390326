import * as React from "react"
import { Link } from "gatsby"
import logo from "../assets/images/logo.svg"
import "../assets/css/home.css"
import Layout from "../components/Layout"

export const Head = () => (
  <>
    <meta name="description" content="HI9 STUDIOS homepage" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale = 1.0, 
          maximum-scale=1.0"
    ></meta>
    <title>HI9 STUDIOS | HOME</title>
  </>
)

const HomePage = () => (
    <Layout>
      <div class="home-page">
        <div class="row">
          <Link to="/comingsoon" className="home-link">
            <button class="action-button">SHOP</button>
          </Link>
          <Link to="/recording" className="home-link">
            <button class="action-button">RECORDING</button>
          </Link>
        </div>
        <div class="row">
          <Link to="/">
            <img class="home-logo" src={logo} alt="HI9 LOGO" />
          </Link>
        </div>
        <div class="row">
          <Link to="/comingsoon" className="home-link">
            <button class="action-button">PHOTOS</button>
          </Link>
          <Link to="/comingsoon" className="home-link">
            <button class="action-button">VIDEOS</button>
          </Link>
        </div>
      </div>
    </Layout>
)

export default HomePage
